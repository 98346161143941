import React from 'react';

import { ERouteType, ICustomRouteObject } from '@trader/navigation';
import { Progress } from '@trader/components';
import { paths } from '@trader/constants';

const CopierLazy = React.lazy(() =>
  import('@trader/pages').then(({ Copier }) => ({
    default: Copier,
  }))
);
const PurchaseChallengesLazy = React.lazy(() =>
  import('@trader/pages').then(({ PurchaseChallenges }) => ({
    default: PurchaseChallenges,
  }))
);

export const challengeRoutes: ICustomRouteObject[] = [
  {
    id: 'mainRoutes_Copier',
    path: paths.main.copier,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <CopierLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_PurchaseChallenges',
    path: paths.main.purchaseChallenges,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <PurchaseChallengesLazy />
      </React.Suspense>
    ),
  },
];
