import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  TradingIsClosed,
  ModalTitle,
  Progress,
  Form,
  Controller,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { calcPositionPl, calculateSpread } from '@trader/utils';
import { IInitialTradingFormValues } from '@trader/types';
import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
} from '@trader/hooks';

import {
  IPositionMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
// import { BuyButton } from '../components/buyButton';
import { ConfirmButton } from '../components/confirmButton';
import { useClosePositionFormValidation } from './formValidation';

import * as Styled from './styled';
import { BuyButton } from '@trader/trading';

const ClosePosition: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const modal = store.ui.modal;
  const positionId = modal.options.get('positionId');

  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  const { validationSchema } = useClosePositionFormValidation(metric?.quantity);

  const handleClosePosition = (data: IInitialTradingFormValues) => {
    if (metric) {
      const body = {
        positionId,
        quantity: Number(data.amount),
      };
      metric.closePositionAsync.run(body)?.then(() => {
        setIsOrderProcessing(true);
      });

      // The values will be used in positions socket
      // to show the order response modal correctly.
      modal.update({
        isOrderCanceled: isInvestmentAccProduct,
        isPositionClosed: !isInvestmentAccProduct,
        isPartiallyClosed: metric.quantity > +Number(data.amount),
        skipOrderIdCheck: isInvestmentAccProduct,
        positionId,
        quantityToClose: data.amount,
      });
    }
  };

  if (applyConditionThird().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyConditionThird()?.action}
      />
    );
  }

  return (
    <Styled.Root>
      <React.Fragment>
        <ModalTitle title={translate('COMMON.LABELS.CLOSE_POSITION')} />
        <Form<IInitialTradingFormValues>
          onSubmit={(_reset, data) => {
            handleClosePosition(data);
          }}
          mode='all'
          defaultValues={{
            amount: metric?.quantity?.toString(),
          }}
          validationSchema={validationSchema}
        >
          {({ formState, control, register }) => (
            <React.Fragment>
              {instrument.isOpenTrading ? (
                <Styled.Position>
                  <TradingModalHeader<IPositionMetricStore>
                    type='position'
                    metric={{
                      ...metric,
                      pl: calcPositionPl(
                        metric.price,
                        metric.openPrice,
                        metric.quantity,
                        metric.exchangeRate,
                        metric.side
                      ),
                    }}
                  />
                  <Styled.ActionButtons>
                    <BuyButton
                      type='modifyOrder'
                      isDisabled
                      isAsk
                      side='Sell'
                    />
                    <Styled.Spread>
                      {calculateSpread({
                        ask: Number(instrument.ask),
                        bid: Number(instrument.bid),
                        pipSize: instrument.pipSize,
                      })}
                    </Styled.Spread>
                    <BuyButton type='modifyOrder' isDisabled side='Buy' />
                  </Styled.ActionButtons>
                  <Styled.Main>
                    <Controller
                      type='orderInput'
                      shouldCheckActivityOfButtons
                      placeholder={translate('COMMON.LABELS.QUANTITY')}
                      maxValue={metric?.quantity || 0}
                      disabled={
                        !instrument.isOpenTrading ||
                        metric?.quantity === instrument?.minOrderSizeIncrement
                      }
                      minValue={instrument.minOrderSize}
                      fixDigitAfterDot={trading.amountDigitsAfterDot()}
                      step={instrument.minOrderSizeIncrement}
                      control={control}
                      {...register('amount')}
                    />
                  </Styled.Main>
                  <ConfirmButton
                    isDisabled={!!formState.errors.amount}
                    isLoading={metric?.closePositionAsync.inProgress}
                    title={
                      isOrderProcessing
                        ? translate('COMMON.LABELS.PROCESSING')
                        : translate('COMMON.LABELS.CLOSE_POSITION')
                    }
                  />
                </Styled.Position>
              ) : (
                <TradingIsClosed
                  iconTypeName='warning'
                  title={translate('WARNINGS.CURRENTLY_OUT_OF_TRADING_HOURS')}
                />
              )}
            </React.Fragment>
          )}
        </Form>
      </React.Fragment>
    </Styled.Root>
  );
});

export const ClosePositionWrapper: React.FC = observer(() => {
  const store = useMst();
  useCloseModalForAlreadyDeletedInstance('positionId');

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const positionId = store.ui.modal.options.get('positionId');
  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  if (!metric) {
    return null;
  }

  return <ClosePosition />;
});
