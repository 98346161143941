import { commonRoutes } from './common';
import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { challengeRoutes } from './challenges';

export const routes = [
  ...commonRoutes,
  ...authRoutes,
  ...mainRoutes,
  ...challengeRoutes,
];
