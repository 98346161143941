import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  Button,
  IconButton,
  Progress,
  Tabs,
  Typography,
} from '@trader/components';
import { isChallengeAccountType, NAVIGATE_TO } from '@trader/constants';
import { BottomBar, Modals, TopBar } from '@trader/containers';
import { useNavigation } from '@trader/hooks';
import { useI18next } from '@trader/services';
import { EChallenge, EChallengeStatus } from '@trader/types';
import { TChallengeEntity, useMst } from '@trader/store';
import { IChallenge as IChallengeBE } from '@trader/api';

import * as Styled from './styled';

const TransactionsLazy = React.lazy(() =>
  import('./components').then(({ Transactions }) => ({
    default: Transactions,
  }))
);
const AccountInformationLazy = React.lazy(() =>
  import('./components').then(({ AccountInformation }) => ({
    default: AccountInformation,
  }))
);
const ChallengesLazy = React.lazy(() =>
  import('@trader/containers').then(({ Challenges }) => ({
    default: Challenges,
  }))
);

export const MyAccount: React.FC = observer(() => {
  const { translate } = useI18next();
  const store = useMst();
  const { navigateTo } = useNavigation();

  const activeChallenge = store.user.getActiveAccountChallenge();
  const isOneMasterAccount = store.entities.challenges
    .getAll<TChallengeEntity>()
    .some(ch => ch.hierarchyType === EChallenge.Master);

  const translationMap: Record<IChallengeBE['result'], string> = {
    Completed: translate('COMMON.LABELS.COMPLETED'),
    Failed: translate('COMMON.LABELS.FAILED'),
    Cancelled: translate('COMMON.LABELS.CANCELLED'),
    'In Progress': translate('COMMON.LABELS.IN_PROGRESS'),
  };

  const tabs = [
    {
      value: 'accountInformation',
      label: translate('COMMON.LABELS.ACCOUNT_INFORMATION'),
      children: (
        <React.Suspense fallback={<Progress />}>
          <AccountInformationLazy />
        </React.Suspense>
      ),
    },
    {
      value: 'transactions',
      label: translate('COMMON.LABELS.TRANSACTIONS'),
      children: (
        <React.Suspense fallback={<Progress />}>
          <TransactionsLazy />
        </React.Suspense>
      ),
    },
  ];

  if (isChallengeAccountType) {
    tabs.unshift({
      value: 'challenges',
      label: translate('COMMON.LABELS.CHALLENGES'),
      children: (
        <React.Suspense fallback={<Progress />}>
          <Styled.PaddingContainer>
            <ChallengesLazy
              shouldDisplayAccTypeIcon={isOneMasterAccount}
              shouldDisplayStatus
              shouldDisplayResult
              isFullHeight
            />
          </Styled.PaddingContainer>
        </React.Suspense>
      ),
    });
  }

  return (
    <Styled.Root>
      <TopBar />
      <Styled.Main>
        <Styled.Title>
          <div>
            <IconButton
              iconType='arrowLeft'
              size='medium'
              onClick={() => navigateTo(NAVIGATE_TO.main.dashboard)}
            />
            <Typography>{translate('COMMON.LABELS.MY_ACCOUNT')}</Typography>
          </div>
          {activeChallenge?.status === EChallengeStatus.Disable && (
            <span>
              {translate('WARNINGS.CHALLENGE_STATUS', {
                status: translationMap[activeChallenge?.result]?.toLowerCase(),
              })}{' '}
              <Button
                onClick={() =>
                  isChallengeAccountType
                    ? navigateTo(NAVIGATE_TO.main.purchaseChallenges)
                    : window.open(import.meta.env.VITE_REGISTRATION_LINK)
                }
                size='small'
                variant='text'
              >
                {translate('COMMON.LABELS.PURCHASE').toLowerCase()}
              </Button>{' '}
              {translate('COMMON.LABELS.A_NEW_ONE')}
            </span>
          )}
        </Styled.Title>
        <Tabs
          tabsPosition='top'
          marginBottom='0'
          tabs={
            import.meta.env.VITE_HASNT_WITHDRAW
              ? tabs.filter(t => t.label !== 'Transactions')
              : tabs
          }
        />
      </Styled.Main>
      <BottomBar />
      <Modals />
    </Styled.Root>
  );
});
